import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import Newsletter from './newslettersignup'
import FooterLogo from './footerlogo'

const FooterArea = styled.footer`
    margin: 3em auto 0;
    padding:2em 0;
    background: ${props => props.theme.black};
    color:white;
    line-height:1.2;


`

const SiteLinks = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:2em;

    @media screen and (max-width:800px) {
    display:block;
    }

    ul {
        margin:0;

        @media screen and (max-width:800px) {
            margin:2em 0; 
         }

    }

    li {
        margin: 1em 0;

    }

    a {
     position: relative;
     transition: all 0.3s ease 0s;
     line-height: '22px';
     display:block;
     max-width:70px;
     &:after {
      position: absolute;
      transition: .3s;
      content: '';
      width: 0;
      left: 0%;
      bottom: 0;
      top:22px;
      height: 1px;
      background: #fff;
  }
  &:hover {
    &:after {
      width: 100%;
      left: 0%;
      }
  }

  }

     a {
        color:white;
    }
`

const FooterContent = styled.div`
    padding:1em 2em;
    max-width:1120px;
    margin:0 auto;
    display:grid;
    grid-template-columns: 200px 1fr 1fr;
    grid-gap:3em;

    @media screen and (max-width:800px) {
    display:block;
    }

    small {
        display:block;
        margin-top:2em;
    }
`

const Footer = ({ siteTitle }) => (
    <FooterArea>
        <FooterContent>
            <div>
                <FooterLogo />
                 <small>© Tamarisc Ventures {new Date().getFullYear()}, All Rights Reserved.</small>
            </div>
        <SiteLinks>
            <ul>
                <h3>Info</h3>
                <li><Link to="/about">about</Link></li>
                <li><Link to="/contact">contact</Link></li>
                <li><Link to="/portfolio">portfolio</Link></li>
            </ul>

            <ul>
                <h3>Social</h3>
                <li><a href="https://angel.co/company/tamarisc" target="_blank" name="tamarisc angel list">Angelist</a></li>
                <li><a href="https://www.linkedin.com/company/tamarisc/" target="_blank" name="tamarisc linkedin">Linkedin</a></li>
            </ul>
        </SiteLinks>
        <Newsletter />
        </FooterContent>
    </FooterArea>
  
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
