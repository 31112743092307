import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from  "./logo.js"
import Nav from  "./nav.js"
import styled from "styled-components"



const HeaderArea = styled.header`
    display: grid;
    grid-template-columns: 150px 1fr ;
    grid-gap:3em;
    max-width:1120px;
    margin: 1em auto;
    padding:0 2em;

    @media screen and (max-width: 850px) {
    grid-template-columns: 150px  ;
        }

   
`


const Header = ({ siteTitle }) => (
    <HeaderArea>
      <Logo />
      <Nav />
    </HeaderArea>
  
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
