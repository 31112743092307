import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from "styled-components"
import Arrow from './arrow'

const NewsletterArea = styled.div`
    display:grid;
    grid-template-columns: 1fr 49px;
    margin-top: 1em;
`
const GetInTouch = styled.div`

    @media screen and (max-width: 850px) {
        display:block;
    }


`

const SignupContainer = styled.div`
    padding:0 2em;

    @media screen and (max-width: 850px) {
        padding:0 ;
    }

`

const SignUp = styled.form`
align-items:center;
grid-gap:2em;


@media screen and (max-width: 850px) {
        display:block;
    }

.email {
    width:100%;
    background:transparent;
    outline:none;
    border:none;
    border-bottom:1px solid #fff;
    line-height:1.6;
    color:white;
    @media screen and (max-width: 850px) {
      margin-bottom:2em;
    }
}
`

const ArrowSubmit = styled.button`
    background:transparent;
    max-width: 51px;
    border: none;
    padding:0;
    color: white;
    cursor:pointer;
`

const NewsletterSignUp = class extends React.Component {

  state = {
    name: null,
    email: null,
}

_handleChange = (e) => {
    console.log({
        [`${e.target.name}`]: e.target.value,
    });
    this.setState({
        [`${e.target.name}`]: e.target.value,
    });
}

_handleSubmit = (e) => {
    e.preventDefault();

    console.log('submit', this.state);

    addToMailchimp(this.state.email, this.state)
        .then(({ msg, result }) => {
            console.log('msg', `${result}: ${msg}`);

            if (result !== 'success') {
                throw msg;
            }
            alert(msg);
        })
        .catch((err) => {
            console.log('err', err);
            alert(err);
        });
}

 render() {
   return (

<SignupContainer>
    <GetInTouch>
        <SignUp onSubmit={this._handleSubmit}>
            <h3>Newsletter sign up</h3>
            <NewsletterArea>
                <input
                    className="email"
                    type="email"
                    onChange={this._handleChange}
                    placeholder="Enter Your Email"
                    name="email"
                />
                <ArrowSubmit type="sumbit"><Arrow /></ArrowSubmit>
            </NewsletterArea>
        </SignUp>
    </GetInTouch>
</SignupContainer>

  


  )}
}

export default NewsletterSignUp