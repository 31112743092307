import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"

const Navigation = styled.nav`
align-self:center;
justify-self: end;
text-align: right;

.navMenu {
  transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
  transition: all 0.5s ease;
  transition-duration: 1s;

}

.navbar-brand {
  padding:0 1em;
}


.navbar-menu {
  display:none;
  @media screen and (min-width: 800px) {
    display: grid;
    
  }
}

.navbar-start {


  ul {
    padding: 0;
    padding-bottom: 0px;
    margin: 0 auto;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3,auto);
    
    @media screen and (max-width: 800px) {
      display: block;
      padding:0 ;
      position:fixed;
      height:100vh;
      width:100vw;
      background:white;
      top:0;
      left:0;
      margin:0;
      z-index:1;
      text-align:center;

    }

    li {
      @media screen and (max-width: 800px) {
        margin:2em 0 ;
      }
    }

   
  }
 

  
  .navbar-item {
    transition: all 0.3s ease 0s;
    position: relative;
    &:after {
      position: absolute;
      transition: .3s;
      content: '';
      width: 0;
      left: 50%;
      bottom: 0;
      top:22px;
      height: 2px;
      background: #333;
  }
  &:hover {
    &:after {
      width: 100%;
      left: 0;
      }
  }
    @media screen and (max-width: 800px) {
      display:block;
      text-align: center;
      margin: 1em 0;
      text-transform: uppercase;
    }
    
  }
}

.navbar-menu{
	transition: height 1s ease-in-out, opacity 1s ease-in-out;
}
.navbar-menu.is-active {
  display: block;
	height: auto;
  opacity: 1;
  z-index:10000;
}


.navbar-burger {
  @media screen and (min-width: 800px) {
    display: none;
  }
    padding-top: 26px;
    color: #000;
    cursor: pointer;
    display: block;
    height: 3.25rem;
    position: absolute;
    right:35px;
    top:20px;
    z-index:10000;
    width: 3.25rem;
    margin-left: auto;
    transition-delay: 2s;
    transition: height .35s ease-in-out,opacity .75s ease-in-out;

    &.is-active {
     transition-delay: 2s;
    transition: height .35s ease-in-out,opacity .75s ease-in-out;
    
    .icon-1 {
      opacity: 0;
      transform: rotate(40deg);
    }
  
    .icon-2 {
      top: 26px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    }
  
    .icon-3 {
      transform: rotate(-42deg);
  }

    }


    span {
      background-color: currentColor;
      display: block;
      height: 1px;
      left: calc(50% - 8px);
      position: absolute;
      -webkit-transform-origin: center;
      transform-origin: center;
      transition-duration: .2s;
      transition-property: background-color,opacity,-webkit-transform;
      transition-property: background-color,opacity,transform;
      transition-property: background-color,opacity,transform,-webkit-transform;
      transition-timing-function: ease-out;
      width:26px;

      &.icon-1 {
      transform: translateY(-8px);
      animation-delay: 0.2s;
    }


      &.icon-3 {
        transform: translateY(8px);
        animation-delay: 250ms;
      }

   
  }

}


`


const LinkList = styled.ul`
   grid-gap:3em;
   list-style:none;

   a {
     color:black;
     position: relative;
     transition: all 0.3s ease 0s;
     line-height: '22px';
     display:block;
     &:after {
      position: absolute;
      transition: .3s;
      content: '';
      width: 0;
      left: 0%;
      bottom: 0;
      top:22px;
      height: 1px;
      background: #333;
  }
  &:hover {
    &:after {
      width: 100%;
      left: 0%;
      }
  }
   }
`

const activeStyle = {
  fontFamily: 'Gotham-Medium',
  
}





const nav = class extends React.Component {
  componentDidMount() {
    // Get all "navbar-burger" elements
   const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
   if ($navbarBurgers.length > 0) {
 
     // Add a click event on each of them
       $navbarBurgers.forEach( el => {
       el.addEventListener('click', () => {
 
         // Get the target from the "data-target" attribute
         const target = el.dataset.target;
         const $target = document.getElementById(target);
 
         // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
         el.classList.toggle('is-active');
         $target.classList.toggle('is-active');
 
       });
     });
   }
 }


 render() {

   return (
    <Navigation role="navigation" aria-label="navigation"> 
        <div className="navbar-brand">
            {/* Hamburger menu */}
            <div className="navbar-burger burger" data-target="navMenu">
              {/* <h3>Menu</h3> */}
              <span class="icon-1"></span>
              <span class="icon-2"></span>
              <span class="icon-3"></span>
            </div>
          </div>
          <div id="navMenu" className="navbar-menu">
          <div className="navbar-start has-text-centered">

          <LinkList>
            <li>
              <Link to="/about" activeStyle={activeStyle}>
                  About
              </Link>
            </li>
            <li>
              <Link to="/portfolio" activeStyle={activeStyle} >
                Portfolio 
              </Link>
              </li>
              <li>
              <Link to="/contact" activeStyle={activeStyle} >
                Contact
              </Link>
              </li>

          </LinkList>
          </div>
          
          </div>

       
    </Navigation>
      
  )}
}

export default nav
